@tailwind base;
@tailwind components;
@tailwind utilities;


.lang-eng{
  font-family: "Noto Sans", serif;
  font-optical-sizing: auto;
}
.lang-sc{
  font-family: "Noto Sans SC", serif;
  font-optical-sizing: auto;
}
.lang-tc{
  font-family: "Noto Sans TC", sans-serif;
  font-optical-sizing: auto;
}
.App {
  text-align: center;
  /* scroll-behavior: smooth; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.p-page{
  padding: 0px 12px;
}
.p-concert{
  padding: 0px 314px;
}
.no-to-wrap{
  text-wrap: nowrap;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row-col{
  flex-direction: row;
}
.row-col-rev{
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.start-to-center{
  align-items: start;
}
/* footer */
.footer-18{
  font-size: 18;
  font-weight: 500;
}
/* home */
.home-title{
  font-size: 50px;
  font-weight: 700;
}
.home-home-title{
  font-size: 50px;
  font-weight: 700;
}
.transportation-title{
  font-size: 50px;
  font-weight: 700;
}
.home-18{
  font-size: 18px;
}
.nav-18{
  font-size: 18px;
  font-weight: 700;
}
.home-22{
  font-size: 22px;
  font-weight: 700;
}
.home-22-text{
  font-size: 22px;
  font-weight: 400;
}
.home-22-text-500{
  font-size: 22px;
  font-weight: 500;
}
.home-25{
  font-size: 25px;
  font-weight: 500;
}
.home-30{
  font-size: 30px;
  font-weight: 500;
}
.home-sec2-pd{
  padding: 57px 67px;
  border-radius: 9999px;
}
.home-sec2-pad{
  padding: 0px 180px
}
.pad-boat-ticket{

}
.d-w-31-m-48{
  width: 31%;
}
.d-w-50-m-100{
  width: 50%;
}
.d-w-23-m-100{
  width: 23%;
}
.title-text-1{
  font-size: 25px;
  font-weight: 700;
}
.items-start-to-center{
  align-items: start;
}
.d-flex-m-hidden{
  display: flex;
}
.d-hidden-m-flex{
  display: none;
}
.d-w-50-m-100{
  width: 50%;
}
.d-pr-48-m-0{
  padding-right: 48px;;
}
.d-p-48-m-16{
  padding: 48px;
}
.map_photo_container{
  border-radius: 81px;
  padding: 12px 24px;
  margin-top: 24px;
}
.d-w-31-m-100{
  width: 31%;
}
.end-to-center{
  align-items: end;
}
.timetable-container{
  height: 900px;
}
.village-ex-h{
  min-height: 500px;
}
.map-min-h{
  min-height: 550px;
}
.d-50w-m-90w{
  width: 50%;
}
.d-40w-m-90w{
  width: 40%;
}
@media only screen and (max-width: 1600px) {
  .home-title{
    font-size: 40px;
  }
  .home-home-title{
    font-size: 40px;
  }
  .transportation-title{
    font-size: 40px;
  }
  /* .home-22{
    font-size: 18px;
  } */
  /* .home-25{
    font-size: 20px;
  } */
  .p-concert{
    padding: 0px 48px;
  }
}
@media only screen and (max-width: 1424px) {
  .timetable-container{
    height: 600px;
  }
}
@media only screen and (max-width: 1024px) {
  .d-50w-m-90w{
    width:85%;
  }
  .d-40w-m-90w{
    width: 90%;
  }
  .map-min-h{
    min-height: 300px;
  }
  .village-ex-h{
    min-height: 300px;
  }
  .row-col{
    flex-direction: column !important;
  }
  .items-start-to-center{
    align-items: center;
  }
  .row-col-rev{
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .no-to-wrap{
    text-wrap: wrap;
  }
  .home-title{
    font-size: 40px;
  }
  .home-home-title{
    font-size: 36px;
  }
  .transportation-title{
    font-size: 25px;
  }
  .footer-18{
    font-size: 10px;
  }
  .home-18{
    font-size: 14px;
  }
  .home-22{
    font-size: 18px;
  }
  .home-22-text{
    font-size: 18px;
  }
  .home-22-text-500{
    font-size: 18px;
  }
  .home-25{
    font-size: 20px;
  }
  .home-sec2-pad{
    padding: 0px 24px;
  }
  .pad-boat-ticket{
    margin-top: 24px;
  }
  .d-w-31-m-100{
    width: 100%;
  }
  .d-w-23-m-100{
    width: 100%;
  }
  .start-to-center{
    align-items: center;
  }
  .p-concert{
    padding: 0px 24px;
  }
  .d-w-50-m-100{
    width: 100%;
  }
  .title-text-1{
    font-size: 22px;
    font-weight: 700;
  }
  .d-flex-m-hidden{
    display: none;
  }
  .d-hidden-m-flex{
    display: flex;
  }
  .d-w-50-m-100{
    width: 100%;
  }
  .d-pr-48-m-0{
    padding-right: 0px;;
  }
  .d-p-48-m-16{
    padding: 16px;;
  }
  .map_photo_container{
    border-radius: 16px;
    padding: 8px 4px;
    margin-top: 12px;
  }
  .end-to-center{
    align-items: center;
  }
}
@media only screen and (max-width: 500px) {
  .row-col{
    flex-direction: column !important;
  }
  .home-title{
    font-size: 30px;
  }
  .home-home-title{
    font-size: 26px;
  }
  .transportation-title{
    font-size: 16px;
  }
  .home-22{
    font-size: 16px;
  }
  .home-25{
    font-size: 18px;
  }
  .home-22-text{
    font-size: 14px;
  }
  .home-sec2-pd{
    padding: 25px 25px !important;
    border-radius: 14px;
  }
}
