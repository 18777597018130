.slide-in-top {
    animation: 0.2s ease-out 0s 1 slideInFromTop;
}
.slide-out-top {
    animation: 0.2s ease-out 0s 1 slideOutFromBottom;
    display: none;
}
.slide-in-right {
    animation: 0.3s ease-out 0s 1 slideInFromRight;
}
.slide-out-right {
    animation: 0.3s ease-out 0s 1 slideOutFromRight;
    display: none;
}
.fade-in-me {
    animation: 0.2s ease-out 0s 0.3 FadeInTemp;
    backdrop-filter: blur(10px);
}
.fade-out-me {
    animation: 0.2s ease-out 0s 0.3 FadeOutTemp;
}
.d-p41-m-p14{
  padding: 0px 41px;
}
.d-100h-m-70h{
  height: 100px;
}
.wgap-14-m-5{
  gap:14px;
  margin-right: 24px;
}
.w-35-m-25{
  font-size: 25px;
}
.w-30-m-18{
  font-size: 18px;
}
.row-col-nav{
  flex-direction: row;
  align-items: center;
}
.nav-img-height{
  height: 100%;
}
.w-justEnd-m-justCen{
  justify-content: end;
}
@media only screen and (min-width: 2500px) {
  .w-35-m-25{
    font-size: 45px;
  }
  .w-30-m-18{
    font-size: 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .w-1200-none{
    display: none;
  }
  
}
@media only screen and (max-width: 1240px) {
  /* .nav-img-height{
    height: 70%;
  } */
  .d-p41-m-p14{
    padding: 0px 14px;
  }
  .d-100h-m-70h{
    height: 100px;
  }
  .wgap-14-m-5{
    gap:5px;
    margin-right: 4px;
  }
  .w-35-m-25{
    font-size: 18px;
  }
  .w-30-m-18{
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .w-justEnd-m-justCen{
    justify-content: center;
  }
  .w-600-none{
    display: none;
  }
}
@media only screen and (max-width: 550px) {
  .d-100h-m-70h{
    height: 120px;
  }
  .nav-img-height{
    height: 60%;
  }
  .row-col-nav{
    flex-direction: column;
  }
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
      display: flex;
    }
}
@keyframes slideOutFromBottom {
    0% {
      transform: translateY(0);
      display: flex;
    }
    100% {
      transform: translateY(-100%);
    }
}
@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
      display: flex;
    }
}
@keyframes slideOutFromRight {
    0% {
      transform: translateX(0);
      display: flex;
    }
    100% {
      transform: translateX(100%);
    }
}
@keyframes FadeInTemp {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(10px);
    }
}
@keyframes FadeOutTemp {
    0% {
        backdrop-filter: blur(10px);
    }
    100% {
      backdrop-filter: blur(0px);
    }
}